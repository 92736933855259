
import { Component, Vue } from "vue-property-decorator";
import {
  apiExpressDetail,
  apiExpressEdit,
  apiExpressAdd,
  apiEngineList,
  apiEngineAccountLists,
  apiProductList,
} from "@/api/setting";
import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";
import { RequestPaging } from "@/utils/util";
import { apiShopLists, apiFactoryLists } from "@/api/shop";

@Component({
  components: {
    MaterialSelect,
  },
})
export default class ExpressEdit extends Vue {
  /** S Data **/
  apiEngineAccountLists = apiEngineAccountLists;
  pager: RequestPaging = new RequestPaging();
  mode: string = PageMode.ADD; // 当前页面: add-添加角色 edit-编辑角色
  disabled: any = false; // 是否禁用 查看详情时禁用
  // 表
  lists: Array<object> = [];

  // 表单数据
  form: any = {
    exname: "",
    // icon: "",
    // code: "",
    // code100: "",
    // codebird: "",
    sort: "",
    status: 1,
    fid: "",
    // AppName: "",
    // AppKey: "",
    // AppSecret: "",
    product_name: "",
    pla: "",
    account_id: "",
    product_id: "",
    deal_sid: 0,
  };
  account = "";
  // 表单校验
  rules: object = {
    exname: [{ required: true, message: "请输入快递名称", trigger: "blur" }],
    pla: [{ required: true, message: "请选平台", trigger: "change" }],
    fid: [{ required: true, message: "请选工厂", trigger: "change" }],
    account_id: [
      { required: true, message: "请选择物流账号", trigger: "change" },
    ],
    product_id: [
      { required: true, message: "请选择物流产品", trigger: "change" },
    ],
  };

  factoryList: any[] = [];

  plaList: any = [
    { value: "Amazon.com", label: "amazon" },
    { value: "lazada", label: "lazada" },
    { value: "original", label: "自有商城" },
    { value: "Temu.com", label: "temu" },
  ];

  productionShow = false;
  accountShow = false;

  engineList: any = [];
  accountData: any = [];
  productionData: any = [];
  productList: any = [];
  type = "";
  shopList: any = [];
  /** E Data **/

  /** S Methods **/
  // 提交表单
  onSubmit(formName: string) {
    const refs = this.$refs[formName] as HTMLFormElement;
    refs.validate((valid: boolean): any => {
      if (!valid) return;
      // 请求发送
      this.form.pla = this.form.pla.join(",");
      this.form = { ...this.form, ...this.productionData };
      switch (this.mode) {
        case PageMode.ADD:
          return this.handleExpressAdd();
        case PageMode.EDIT:
          return this.handleExpressEdit();
      }
    });
  }

  // 添加
  async handleExpressAdd(): Promise<void> {
    await apiExpressAdd(this.form);
    setTimeout(() => this.$router.go(-1), 500);
  }

  // 编辑
  async handleExpressEdit(): Promise<void> {
    await apiExpressEdit(this.form);
    setTimeout(() => this.$router.go(-1), 500);
  }

  // 初始化表单数据
  async initFormDataForNoticeEdit(): Promise<void> {
    const res = await apiExpressDetail({
      id: this.form.id,
    });
    this.account = res.account.title;
    res.pla = typeof res.pla == "string" ? res.pla.split(",") : res.pla;
    apiProductList({ account_id: res.account_id }).then((res) => {
      this.productList = res;
    });
    Object.keys(res).map((item) => {
      this.$set(this.form, item, res[item]);
    });
    console.log(this.form);
  }

  changeExpress() {
    this.getExpressLists();
    this.form.account_id = ''
    this.form.product_id = ''
  }

  getExpressLists(): void {
    this.pager
      .request({
        callback: apiEngineAccountLists,
        params: {
          fid: this.form.fid,
        },
      })
      .then((res) => {
        this.accountData = res.lists;
      });
  }
  changeType() {
    apiProductList({ account_id: this.form.account_id }).then((res) => {
      this.productList = res;
    });
  }

  productChange(e: any) {
    for (let i of this.productList) {
      if (i.product_id == e) {
        this.productionData = i;
      }
    }
  }
  // 工厂管理列表
  getFactoryLists() {
    apiFactoryLists({}).then((res) => {
      this.factoryList = res.lists;
    });
  }
  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query;
    if (query.mode) this.mode = query.mode;
    this.getFactoryLists();

    if (this.mode === PageMode.EDIT) {
      this.form.id = query.id * 1;
      this.initFormDataForNoticeEdit();
      this.getExpressLists();
    }

    apiShopLists({ page_size: 9999 }).then((res) => {
      this.shopList = res.lists;
      this.shopList.unshift({ id: 0, name: "全部" });
    });
    apiEngineList({}).then((res) => {
      let keys = Object.keys(res);
      for (let i of keys) {
        this.engineList.push({ value: i, label: res[i] });
      }
    });
  }

  /** E Life Cycle **/
}
